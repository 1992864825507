.time-category-page {

    .time-category-page-top {
        display: grid;
        gap: $gap-3;
        max-width: 1000px;
        margin: auto;
        margin-bottom: $gap-3;
    }

    .lytiko-chart, .time-category-chart {
        max-width: 1000px;
        margin: auto;
        margin-bottom: $gap-3;
    }

    .sessions-table {
        max-width: 1000px;
        margin: auto;
    }

    @media (min-width: 900px) {
        .time-category-page-top {
            grid-template-columns: 1fr 450px;
        }
    }
}