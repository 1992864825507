.food-page {

    .food {
        margin-bottom: $gap-3;
    }
}

.food-page > div {
    max-width: 1000px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}