$series-one-color: #e74c3c;
$series-two-color: #778beb;

$series-one: transparentize($series-one-color, 0.8);
$series-two: transparentize($series-two-color, 0.8);

.comparison-chart {
    .highcharts-markers.first-series {
        path {
            fill: $series-one;
        }
    }

    .highcharts-line-series.first-series, .highcharts-line-series.trendline-1 {
        path.highcharts-graph {
            stroke: $series-one-color;
        }
    }

    .highcharts-column-series.first-series {
        rect {
            fill: transparentize($series-one, 0.1);
            stroke: transparentize($series-one, 0.1);;
        }
    }

    .highcharts-markers.second-series {
        path {
            fill: $series-two;
        }
    }

    .highcharts-line-series.second-series , .highcharts-line-series.trendline-2 {
        path.highcharts-graph {
            stroke: $series-two-color;
        }
    }

    .highcharts-column-series.second-series {
        rect {
            fill: transparentize($series-two, 0.1);
            stroke: transparentize($series-two, 0.1);;
        }
    }

    .highcharts-line-series.first-trendline {
        path.highcharts-graph {
            stroke: darken($series-one-color, 30);
        }
    }

    .highcharts-line-series.second-trendline {
        path.highcharts-graph {
            stroke: darken($series-two-color, 30);
        }
    }

    .highcharts-axis.y-axis-1 {
        text.highcharts-axis-title {
            tspan {
                fill: $series-one-color;
            }
        }
    }

    .highcharts-yaxis-labels.y-axis-1 {
        text {
            fill: darken($series-one-color, 25) !important;
        }
    }

    .highcharts-axis.y-axis-2 {
        text.highcharts-axis-title {
            tspan {
                fill: $series-two-color;
            }
        }
    }

    .highcharts-yaxis-labels.y-axis-2 {
        text {
            fill: darken($series-two-color, 25) !important;
        }
    }
}

.comparison-chart.shared-axis {
    .highcharts-yaxis-labels.y-axis-1 {
        text {
            fill: $text-black !important;
        }
    }
}