.food-form {
    max-width: 600px;
    margin: 0 auto;

    h1 {
        border-bottom: 2px solid $food-color;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    form {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    h2 {
        margin-top: $gap-6;
    }

    label {
        .toggler {
            position: relative;
            top: -$gap-2;
            left: $gap-4;
            font-size: $font-size-1;
        }
    }

    input.read-only {
        cursor: default;
    }

    input.read-only:focus {
        outline: none;
    }

    .toggler {
        color: $hyperlink-color;
        font-size: $font-size-2;
        display: inline;
        cursor: pointer;
        font-weight: 600;
        cursor: pointer;
    }

    .nutrient-inputs.hidden {
        display: none;
    }

    .nutrient-inputs {
        display: grid;
        gap: $gap-4;
        margin-bottom: $gap-6;

        .input {
            margin-bottom: 0;
        }
    }
    
    .portion-calculator {
        display: grid;
        grid-template-columns: 70px 70px;

        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
            text-align: right;
        }

        .units {
            border: 1px solid $border-color;
            display: flex;
            padding: $gap-2 0;
            line-height: 1;
            border-left: none;
            background-color: white;
            font-weight: 300;
            width: 30px;
            border-top-right-radius: $small-border-radius;
            border-bottom-right-radius: $small-border-radius;
        }

        .react-select__control {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            padding-bottom: 1px;
        }
    }

    input[type=submit] {
        padding-left: $gap-5;
        padding-right: $gap-5;
        margin-top: $gap-6;
    }

    @media (min-width: 300px) {

        .nutrient-inputs {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: 400px) {
        .nutrient-inputs {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .bottom-row {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            .input, input {
                margin: 0;
                height: fit-content;
            }
        }

        input[type=submit] {
            width: fit-content;
        }
    }

    @media (min-width: 500px) {
        .top-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 200px 1fr;
            align-items: end;
        }
    }

    @media (min-width: 700px) {
        .nutrient-inputs {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}