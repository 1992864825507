.meal {
    position: relative;
    height: fit-content;

    .time {
        font-weight: 300;
        margin-bottom: $gap-3;
        color: black;
    }

    .components {
        font-size: $font-size-2;
        display: grid;
        gap: $gap-2;
        font-weight: 600;
        margin-bottom: $gap-3;

        a {
            font-weight: 700;
        }

        .amount {
            color: $text-gray;
            font-size: $font-size-1;
            font-weight: 200;
        }

        .note {
            margin: auto;
            display: flex;
            height: 30px;
            align-items: center;
            font-weight: 200;
        }
    }

    .meal-nutrition {
        color: $text-gray;
        font-weight: 300;
        font-size: $font-size-1;

        .nutrient {
            display: inline-block;
            width: 70px;
        }
    }

    @media (min-width: 250px) {
        .components {
            font-size: $font-size-3;

            .amount {
                font-size: $font-size-2;
                
                .units {
                    font-size: $font-size-1;
                }
            }
        }

        .meal-nutrition {
            font-size: $font-size-1;
        }
    }
    
    @media (min-width: 300px) {
        .components {
            font-size: $font-size-4;

            .amount {
                font-size: $font-size-3;
                
                .units {
                    font-size: $font-size-2;
                }
            }

            .note {
                height: 40px;
            }
        }
    }

    @media (min-width: 350px) {
        .meal-nutrition {
            font-size: $font-size-1;
    
            .nutrient {
                line-height: 1.5;
            }
        }
    }

    @media (min-width: 400px) {
        padding: $gap-4;
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: $gap-5;

        .time {
            margin-bottom: $gap-4;
        }

        .components {
            font-size: $font-size-5;
            gap: $gap-3;

            .amount {
                font-size: $font-size-4;
                
                .units {
                    font-size: $font-size-3;
                }
            }

            .note {
                height: 50px;
            }
        }

        .meal-nutrition {
            padding-top: 0;
            height: fit-content;
            margin: auto;
            line-height: 2;
        }
    }

    @media (min-width: 500px) {

        .time {
            margin-bottom: $gap-5;
        }

        .meal-left {
            border-right: 1px solid $border-color;
        }

        .components {
            gap: $gap-4;
        }

        .meal-nutrition {
            font-size: $font-size-2;

            .nutrient {
                width: 80px;

                .number {
                    font-size: $font-size-5;
                }

                .units {
                    font-size: $font-size-1;
                }

                .name {
                    font-size: $font-size-2;
                }
            }
        }
    }

    @media (min-width: 600px) {
        gap: $gap-5;
        grid-template-columns: 3fr 1fr;

        .meal-nutrition {
            font-size: $font-size-3;

            .nutrient {
                width: 90px;
            }
        }
    }
}