.timelines {
    overflow-x: scroll;
    padding-left: 0;

    svg {
        margin: 0 auto;
        display: block;
        position: relative;
        padding-left: $gap-3;
        padding-right: $gap-3;

        rect.timeline {
            fill: #e4e9f2;
        }

        rect.session {
            cursor: pointer;
        }

        line {
            stroke: rgba(24, 33, 37, 0.2);
            stroke-dasharray: 2;
        }

        text {
            font-size: $font-size-1;
            fill: $text-gray;
        }
    }
}

.timelines.no-data {
    max-width: 600px;
    padding: $gap-5;
    text-align: center;
}