.time-page {
    .heading, h2, .time-category-grid, .timelines, .sessions-table {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .heading {
        position: relative;
        display: flex;
        align-items: flex-start;

        .datetime-input {
            width: 30px;
            overflow: visible;

            img {
                cursor: pointer;
            }

            input {
                padding-right: 0;
                background: none;
                border: none;
            }

            input:focus {
                outline: none;
            }
        }

        .datetime-input:focus {
            outline: none;
        }

        .quick-add {
            background: none;
            color: $hyperlink-color;
            font-size: $font-size-1;
            font-weight: 400;
            padding-right: 0;
            padding-top: 0;
        }

        .quick-add:focus {
            outline: none;
        }
    }

    .time-category-grid, .timelines {
        margin-bottom: $gap-5;
    }

    @media (min-width: 400px) {
        .heading {
            .quick-add {
                font-size: $font-size-2;
            }
        }
    }

    @media (min-width: 600px) {
        .heading {
            .quick-add {
                font-size: $font-size-3;
            }
        }
    }

    @media (min-width: 900px) {
        h2 {
            font-size: $font-size-8;
        }
    }
}