.quantity-form {
    overflow: hidden;
    max-width: 600px;
    margin: 0 auto;

    h1 {
        border-bottom: 2px solid $quantities-color;
    }

    .input.has-advanced-toggle {
        flex-wrap: wrap;

        .toggler {
            flex-grow: 1;
            margin-left: auto;
            margin-top: $gap-5;
            color: $hyperlink-color;
            width: 100%;
            cursor: pointer;
            font-size: $font-size-2;
        }
    }

    .input.has-extra-label {
        flex-wrap: wrap;

        img {
            width: 15px;
            opacity: 0.5;
            margin-bottom: $gap-2;
            margin-left: $gap-1;
            cursor: pointer;
        }

        img:hover {
            opacity: 0.8;
        }

        label.extra {
            width: 100%;
            margin-left: 0;
            margin-top: $gap-1;
            font-size: $font-size-1;
            padding-bottom: $gap-1;
        }
    }

    .advanced {
        display: none;
        padding: $gap-3 0;
        background-color: darken($dirty-white, 3);

        label {
            margin-left: $gap-2;
            margin-bottom: $gap-2; 
        }

        .resolution-label {
            margin-top: $gap-5;
        }

        .series {
            font-size: $font-size-1;

            .options {
                display: grid;
                
                .series-type {
                    cursor: pointer;
                    padding: $gap-3 $gap-2;
                }
    
                .series-type.selected {
                    font-weight: bold;
                    background-color: darken($dirty-white, 1);
                }
            }

            .explanation {
                padding: $gap-3;
                background-color: darken($dirty-white, 1);
                color: $text-gray;
                display: none;

                .series-name {
                    display: none;
                }

                .description {
                    font-size: $font-size-3;
                    line-height: 1.6;
                    margin-bottom: $gap-5;
                    font-weight: 300;
                }

                .examples {
                    font-style: italic;
                }
            }

        }

        .resolutions {
            //display: flex;
            font-size: $font-size-1;
            padding-left: $gap-1;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            
            .resolution {
                display: inline;
                padding: $gap-1 $gap-1;
                cursor: pointer;
                color: $text-gray;
                white-space: nowrap;
            }

            .selected {
                font-weight: bold;
                color: $text-black;
            }
        }

        .resolutions::-webkit-scrollbar {
            display: none;
        }
    }

    input[type=submit] {
        padding-left: $gap-5;
        padding-right: $gap-5;
        margin-top: $gap-6;
    }

    @media (min-width: 300px) {
        .advanced {
    
            .series {
                font-size: $font-size-2;
            }
        }
    }

    @media (min-width: 400px) {
        .top-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 1fr 100px;
            align-items: end;
        }

        .input.has-advanced-toggle {
            flex-wrap: nowrap;
    
            .toggler {
                text-align: right;
                margin-top: 0;
                width: fit-content;
            }
        }

        input[type=submit] {
            width: fit-content;
        }

        .advanced {
            padding: $gap-4 0;

            .resolutions {
                font-size: $font-size-2;
                padding-left: 0;
                
                .resolution {
                    padding: $gap-1 $gap-2;
                }
            }
        }
    }

    @media (min-width: 500px) {
        .advanced {
            .resolution-label {
                margin-top: $gap-6;
            }

            .series {
                display: grid;
                grid-template-columns: 180px 1fr;

                .explanation {
                    display: block;
                    font-size: $font-size-3;
                    padding: $gap-3 $gap-4;
                }
            }
        }
    }

    @media (min-width: 600px) {
        .advanced {

            .series {

                .explanation {
                    display: block;
                }
            }
        }
    }
}

.quantity-form.show-advanced {
    .advanced {
        display: block;
    }
}