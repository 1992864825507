.quantity-chart {
    .highcharts-column-series {
        rect {
            fill: transparentize($border-color, 0.3);
            stroke: transparentize($border-color, 0.2);
        }
    }

    .highcharts-column-series.highcharts-series-hover {
        rect {
            fill: transparentize($border-color, 0.2);
            stroke: transparentize($border-color, 0.1);
        }
    }
}

.lytiko-chart {

    .highcharts-background {
        fill: $dirty-white;
    }

    .highcharts-markers {
        path {
            fill: transparentize($border-color, 0.1);
        }
    }

    .highcharts-line-series {        
        path.highcharts-graph {
            stroke: $line-chart-color;
        }
    }

    

    .highcharts-title, .highcharts-axis-labels, .highcharts-axis-title {
        font-family: $font;
    }

    .highcharts-tooltip-box {
        stroke: transparentize($border-color, 0.5);
        fill: white;
        border-radius: 40px;
    }

    .tooltip-top, tspan {
        font-size: $font-size-1;
        fill: $text-gray;
    }

    .tooltip-bottom {
        font-size: $font-size-4;
        fill: $text-black;
        line-height: 1;
    }

    .tooltip-middle {
        font-style: italic;
        font-size: $font-size-1;
        fill: $text-black;
    }

    .tooltip-total {
        font-size: $font-size-2;
    }
}

.loading-chart {
    height: 412px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.chart-controls {
    padding: $gap-3 $gap-1 0 $gap-1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    label {
        font-size: $font-size-1;
    }

    .trendline-toggle {
        margin-bottom: $gap-5;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .resolutions {
        display: flex;
        height: 50px;
        font-size: $font-size-1;
        flex-direction: column;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        .resolution {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 $gap-2 0 0;
        }

        .resolution:last-child {
            margin: 0;
        }

        .selected {
            font-weight: 900;
            cursor: auto;
        }
    }

    @media (min-width: 250px) {
        .resolutions {
            flex-direction: row;
        }
    }

    @media (min-width: 300px) {
        padding: $gap-4 $gap-2 0 $gap-2;
    
        label {
            font-size: $font-size-2;
        }

        .resolutions {
            font-size: $font-size-2;
        }
    }
    
    @media (min-width: 400px) {
        padding: $gap-5 $gap-3 0 $gap-3;
    }

    @media (min-width: 600px) {
        flex-direction: row;
        
        .trendline-toggle, .resolutions {
            margin: 0;
            height: 30px;
        }
    }
}


.quantity-chart.has-trendline {
    .highcharts-markers {
        path {
            fill: transparentize($border-color, 0.6);
        }
    }
}