.quantity-page {

    .quantity-page-top {
        display: grid;
        gap: $gap-3;
        max-width: 1000px;
        margin: auto;
        margin-bottom: $gap-3;
    }

    .lytiko-chart, .quantity-chart {
        max-width: 1000px;
        margin: auto;
        margin-bottom: $gap-3;
    }

    .measurements-table {
        max-width: 1000px;
        margin: auto;
    }

    @media (min-width: 700px) {
        .quantity-page-top {
            grid-template-columns: 1fr 350px;
        }
    }
}