.session-form {
    overflow: hidden;

    h1 {
        border-bottom: 2px solid $quantities-color;
    }

    input[type=submit] {
        max-width: 100px;
        margin-left: auto;
    }

    @media (min-width: 400px) {
        .bottom-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 1fr 50px;
            align-items: end;

            .input {
                margin-bottom: 0;
            }

            input {
                height: 33px;
            }
        }
    }

    @media (min-width: 600px) {
        .top-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 1fr 1fr;
            align-items: end;
        }
    }
}