svg.svg-series {
    width: 100%;
    height: 80px;

    path {
        stroke: $border-color;
    }

    circle {
        fill: $border-color;
    }
}