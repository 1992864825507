.time-category {
    color: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-row {
        margin-bottom: $gap-4;

        .info {
            margin-right: 20px;

            .category-name {
                font-weight: 700;
                font-size: $font-size-4;
                margin-bottom: $gap-1;
            }
        
            .category-description {
                font-size: $font-size-2;
                color: $text-gray;
                line-height: 1.6;
            }
        }
    }

    .bottom-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .total {
            font-weight: 300;
            font-size: $font-size-4;
            white-space: nowrap;
        }

        .series-container {
            text-align: center;
            width: 100%;

            .svg-series {
                max-width: 200px;
                max-height: 20px;
            }
        }
    }

    @media (min-width: 300px) {
        padding: $gap-4;

        .top-row {
            gap: $gap-4;
    
            .info {
                .category-name {
                    font-size: $font-size-5;
                    margin-bottom: $gap-2;
                }
            
                .category-description {
                    font-size: $font-size-3;
                }
            }
        }

        .bottom-row {
            .series-container {
                margin-left: auto;
                margin-right: auto;
    
                .svg-series {
                    max-height: 30px;
                }
            }
        }
    }

    @media (min-width: 350px) {
        padding: $gap-4 $gap-5;
        
        .top-row {
            .info {
                margin-right: 25px;

                .category-name {
                    font-size: $font-size-6;
                }
            
                .category-description {
                    font-size: $font-size-4;
                }
            }
        }

        .bottom-row {
            .total {
                font-size: $font-size-5;
            }

            .series-container {
    
                .svg-series {
                    max-height: 40px;
                }
            }
        }
    }

    @media (min-width: 400px) {
        padding: $gap-5;
        
        .top-row {
            .info {
                .category-name {
                    font-size: $font-size-7;
                }
            }
        }

        .bottom-row {
            .total {
                font-size: $font-size-6;
            }
        }
    }

    @media (min-width: 700px) {
        .top-row {
            margin-bottom: $gap-5;
        }
    }
}

a.time-category {
    border-top: 10px solid black;
}