.name-form-modal {
    width: 100%;

    form {
        display: grid;

        input[type=text] {
            margin: 0;
            font-weight: 700;
            font-size: $font-size-4;
            color: $text-black;
        }

        input:first-child {
            border-bottom-width: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        input:nth-child(2) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    @media(min-width: 300px) {
        form {
            grid-template-columns: 1fr 1fr;
    
            input:first-child {
                border-bottom-width: 1px;
                border-right-width: 0;
                border-bottom-left-radius: $small-border-radius;
                border-top-right-radius: 0;
            }
    
            input:nth-child(2) {
                border-top-right-radius: $small-border-radius;
                border-bottom-left-radius: 0;
            }

            input[type=submit] {
                grid-column: 1 / 4;
            }
        }
    }

    @media(min-width: 400px) {
        

        form {
            margin-top: $gap-4;
        }
    }
}