.dropdown-container {
    position: absolute;
    width: 20px;
    right: $gap-2;
    top: $gap-2;

    img.arrow {
        display: block;
        border-radius: 50%;
        width: 100%;
        padding: $gap-1;
        cursor: pointer;
        opacity: 0.5;
    }

    img.arrow:hover {
        opacity: 1;
        background-color: darken($color: $dirty-white, $amount: 5);
    }

    .dropdown {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        overflow: hidden;
        z-index: 1000;

        .option {
            display: grid;
            grid-template-columns: 15px 1fr;
            align-items: center;
            gap: $gap-3;
            padding: $gap-3 $gap-4;
            color: inherit;
            cursor: pointer;

            img {
                display: block;
                width: 100%;
                opacity: 0.5;
            }
        }

        .option:hover {
            background-color: darken($color: $dirty-white, $amount: 5);
        }
    }

    @media (min-width: 350px) {
        width: 25px;
        right: $gap-3;
        top: $gap-3;
    }
}

.dropdown-container.visible {
    .dropdown {
        display: block;
    }
}