.quantity {
    color: inherit;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-row {
        margin-bottom: $gap-4;

        .info {
            margin-right: 20px;

            .quantity-name {
                font-weight: 700;
                font-size: $font-size-4;
                margin-bottom: $gap-1;
            }
        
            .quantity-description {
                font-size: $font-size-2;
                color: $text-gray;
                line-height: 1.6;
            }
        }
    }

    .bottom-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .value {
            font-size: $font-size-7;
            white-space: nowrap;
    
            .units {
                font-size: $font-size-4;
            }
        }

        .series-container {
            text-align: right;
            width: 100%;
            margin-left: $gap-2;

            .svg-series {
                max-width: 200px;
                max-height: 20px;
            }
        }
    }

    @media (min-width: 300px) {
        padding: $gap-4;

        .top-row {
            gap: $gap-4;
    
            .info {
                .quantity-name {
                    font-size: $font-size-5;
                    margin-bottom: $gap-2;
                }
            
                .quantity-description {
                    font-size: $font-size-3;
                }
            }
        }

        .bottom-row {
            .value {
                font-size: $font-size-8;
            }

            .series-container {
                margin-left: $gap-3;
    
                .svg-series {
                    max-height: 30px;
                }
            }
        }
    }

    @media (min-width: 350px) {
        padding: $gap-4 $gap-5;
        
        .top-row {
            .info {
                margin-right: 25px;

                .quantity-name {
                    font-size: $font-size-6;
                }
            
                .quantity-description {
                    font-size: $font-size-4;
                }
            }
        }

        .bottom-row {
            .value {
                font-size: $font-size-9;
            }

            .series-container {
    
                .svg-series {
                    max-height: 40px;
                }
            }
        }
    }

    @media (min-width: 400px) {
        padding: $gap-5;
        
        .top-row {
            .info {
                .quantity-name {
                    font-size: $font-size-7;
                }
            }
        }
        .bottom-row {
            .value {
                font-size: $font-size-10;
            }
        }
    }

    @media (min-width: 700px) {
        .top-row {
            margin-bottom: $gap-5;
        }
    }
}