.nutrient-chart {
    position: relative;
    padding-top: $gap-3 + 20px;

    @media (min-width: 350px) {
        padding-top: $gap-3 + 25px;
    }
}

.calories-chart {
    .highcharts-column-series {
        rect {
            fill: transparentize($calorie-color, 0.3);
            stroke: transparentize($calorie-color, 0.2);;
        }
    }

    .highcharts-column-series.highcharts-series-hover {
        rect {
            fill: transparentize($calorie-color, 0.2);
            stroke: transparentize($calorie-color, 0.1);;
        }
    }

    .chart-controls {
        padding: $gap-3 $gap-1;

        label {
            font-size: $font-size-1;
        }
    }
}

.carbohydrates-chart {
    .highcharts-column-series {
        rect {
            fill: transparentize($carbohydrates-color, 0.3);
            stroke: transparentize($carbohydrates-color, 0.2);;
        }
    }

    .highcharts-column-series.highcharts-series-hover {
        rect {
            fill: transparentize($carbohydrates-color, 0.2);
            stroke: transparentize($carbohydrates-color, 0.1);;
        }
    }
}

.sugar-chart {
    .highcharts-column-series {
        rect {
            fill: transparentize($sugar-color, 0.3);
            stroke: transparentize($sugar-color, 0.2);;
        }
    }

    .highcharts-column-series.highcharts-series-hover {
        rect {
            fill: transparentize($sugar-color, 0.2);
            stroke: transparentize($sugar-color, 0.1);;
        }
    }
}

.protein-chart {
    .highcharts-column-series {
        rect {
            fill: transparentize($protein-color, 0.3);
            stroke: transparentize($protein-color, 0.2);;
        }
    }

    .highcharts-column-series.highcharts-series-hover {
        rect {
            fill: transparentize($protein-color, 0.2);
            stroke: transparentize($protein-color, 0.1);;
        }
    }
}

.fat-chart {
    .highcharts-column-series {
        rect {
            fill: transparentize($fat-color, 0.3);
            stroke: transparentize($fat-color, 0.2);;
        }
    }

    .highcharts-column-series.highcharts-series-hover {
        rect {
            fill: transparentize($fat-color, 0.2);
            stroke: transparentize($fat-color, 0.1);;
        }
    }
}

.fibre-chart {
    .highcharts-column-series {
        rect {
            fill: transparentize($fibre-color, 0.3);
            stroke: transparentize($fibre-color, 0.2);;
        }
    }

    .highcharts-column-series.highcharts-series-hover {
        rect {
            fill: transparentize($fibre-color, 0.2);
            stroke: transparentize($fibre-color, 0.1);;
        }
    }
}

.salt-chart {
    .highcharts-column-series {
        rect {
            fill: transparentize($salt-color, 0.3);
            stroke: transparentize($salt-color, 0.2);;
        }
    }

    .highcharts-column-series.highcharts-series-hover {
        rect {
            fill: transparentize($salt-color, 0.2);
            stroke: transparentize($salt-color, 0.1);;
        }
    }
}

.nutrient-chart {

    @media (min-width: 300px) {
        .chart-controls {
            padding: $gap-4 $gap-2;
    
            label {
                font-size: $font-size-2;
            }
        }
    }
    
    @media (min-width: 400px) {
        .chart-controls {
            padding: $gap-5 $gap-3;
        }
    }
}
