.privacy-policy-page {
    background-color: $dirty-white;
    padding: $gap-4 $gap-3;
    font-size: $font-size-3;

    h1 {
        font-weight: 800;
        font-size: $font-size-7;
        margin-bottom: $gap-5;
    }

    h2 {
        font-weight: 600;
        margin-top: $gap-4;
        font-size: $font-size-6;
    }

    p {
        font-weight: 200;
        margin: $gap-2 0;
        line-height: 1.5;

        a {
            font-weight: 300;
        }
    }
    @media (min-width: 300px) {
        padding: $gap-6 $gap-5;
        font-size: $font-size-4;
    
        h1 {
            font-size: $font-size-8;
            margin-bottom: $gap-6;
        }
    
        h2 {
            margin-top: $gap-5;
            font-size: $font-size-7;
        }
    
        p {
            margin: $gap-4 0;
        }
    }
    
    @media (min-width: 400px) {
        h1 {
            font-size: $font-size-9;
        }
    }
    
    @media (min-width: 500px) {
        padding: $gap-7 $gap-6;
    
        h1, h2, p {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    
    @media (min-width: 600px) {
        h1, h2, p {
            max-width: 550px;
        }
    }
}
