.account-form {
    overflow: hidden;
    max-width: 600px;
    margin: 0 auto;

    h1 {
        border-bottom: 2px solid $money-color;
    }

    p {
        font-weight: 700;
        color: $text-gray;
        font-size: $font-size-1;
        margin-bottom: $gap-1;
    }

    .input.has-advanced-toggle {
        flex-wrap: wrap;

        .toggler {
            flex-grow: 1;
            margin-left: auto;
            margin-top: $gap-5;
            color: $hyperlink-color;
            width: 100%;
            cursor: pointer;
            font-size: $font-size-2;
        }
    }

    .input.has-extra-label {
        flex-wrap: wrap;

        img {
            width: 15px;
            opacity: 0.5;
            margin-bottom: $gap-2;
            margin-left: $gap-1;
            cursor: pointer;
        }

        img:hover {
            opacity: 0.8;
        }

        label.extra {
            width: 100%;
            margin-left: 0;
            margin-top: $gap-1;
            font-size: $font-size-1;
            padding-bottom: $gap-1;
        }
    }

    .advanced {
        display: none;
        padding: $gap-3 0;
        background-color: darken($dirty-white, 3);

        label {
            margin-left: $gap-2;
            margin-bottom: $gap-2; 
        }

        .resolution-label {
            margin-top: $gap-5;
        }
    }

    input[type=submit] {
        padding-left: $gap-5;
        padding-right: $gap-5;
        margin-top: $gap-6;
    }

    @media (min-width: 300px) {
        p {
            font-size: $font-size-2;
            margin-top: $gap-5;
            margin-bottom: $gap-2;
        }

        .toggle-row {
            margin-top: $gap-4;
        }
    }

    @media (min-width: 400px) {
        .top-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 1fr 100px;
            align-items: end;
        }

        .start-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 1fr 100px;
        }

        .input.has-advanced-toggle {
            flex-wrap: nowrap;
    
            .toggler {
                text-align: right;
                margin-top: 0;
                width: fit-content;
            }
        }

        input[type=submit] {
            width: fit-content;
        }

        .advanced {
            padding: $gap-4 0;

            .resolutions {
                font-size: $font-size-2;
                padding-left: 0;
                
                .resolution {
                    padding: $gap-1 $gap-2;
                }
            }
        }
    }

    @media (min-width: 500px) {

        p {
            margin-top: $gap-6;
            margin-bottom: $gap-3;
        }

        .toggle-row {
            display: flex;
            margin-top: $gap-5;
            justify-content: space-between;
        }
    }

    @media (min-width: 600px) {
        .advanced {

            .series {

                .explanation {
                    display: block;
                }
            }
        }
    }
}

.account-form.show-advanced {
    .advanced {
        display: block;
    }
}