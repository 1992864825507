.transaction-form {
    overflow: hidden;

    h1 {
        border-bottom: 2px solid $quantities-color;
    }

    input[type=submit], button {
        width: 100px;
        margin-left: auto;
        display: block;
        background-color: $primary;
        color: white;
        height: 34px;
        font-size: $font-size-2;
    }

    @media (min-width: 400px) {
        .top-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 75px 1fr;
            align-items: end;
        }
    }
}