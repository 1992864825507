.table-paginator {
    display: flex;
    font-size: $font-size-1;
    padding: $gap-4 $gap-1;
    justify-content: center;
    align-items: center;

    .navigator {
        margin: 0 $gap-2;
        cursor: pointer;
    }

    img {
        width: 15px;
    }

    img.disabled {
        opacity: 0.3;
        cursor: default;
    }

    .number {
        display: none;
        width: 20px;
        height: 20px;
        justify-content: center;
        align-items: center;
        margin: 0 $gap-1;
        border-radius: 50%;
    }

    .number.selected, .number.selected:hover {
        background-color: $primary;
        color: white;
        cursor: default;
    }

    @media (min-width: 250px) {
        padding: $gap-5 $gap-2;

        .number.selected {
            display: flex;
        }
    }

    @media (min-width: 300px) {
        .number.proximate {
            display: flex;
        }
    }

    @media (min-width: 400px) {
        font-size: $font-size-1;

        .number {
            display: flex;
        }

        .number:hover {
            background-color: transparentize($primary, 0.5);
        }
    }

    @media (min-width: 500px) {
        font-size: $font-size-2;

        img {
            width: 15px;
        }

        .navigator {
            margin: 0 $gap-3;
        }

        .number {
            width: 25px;
            height: 25px;
            margin: 0 $gap-1;
        }
    }
}