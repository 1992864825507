nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100vw - #{$compact-sidebar-width});
    padding: 0 $gap-4;

    .nav-dropdown-container {
        width: fit-content;
        height: 40%;
        display: flex;
        justify-content: flex-end;

        img {
            display: block;
            height: 100%;
            opacity: 0.3;
            cursor: pointer;
        }
    
        .nav-dropdown {
            display: none;
            position: absolute;
            z-index: 100;
            top: $nav-height;
            right: $gap-3;
        }

    }

    @media (min-width: 800px) {
        width: calc(100vw - #{$extended-sidebar-width});
    }
}

nav.show-dropdown {
    .nav-dropdown-container {
        .nav-dropdown {
            display: block;
        }
    }
}