.credentials-form {
    
    h2 {
        font-weight: 600;
        font-size: $font-size-3;
        margin-bottom: $gap-2;
        margin-left: $gap-1;
    }

    input {
        margin-bottom: $gap-4;
    }

    input[type=submit] {
        font-size: $font-size-2;
        margin-top: $gap-4;
        margin-bottom: 0;
    }

    .credentials-top {
        display: grid;
        gap: $gap-2;
        margin-bottom: $gap-5;

        .basic-info {
            .name {
                font-weight: 700;
                font-size: $font-size-5;
                margin-bottom: $gap-2;
                line-height: 1;
                cursor: pointer;
            }
        
            .date-joined {
                font-size: $font-size-1;
                color: $black-2;
            }
        }

        .account-status {
            font-weight: 700;
            font-size: $font-size-1;
            color: $text-gray;
            
            span {
                display: inline-block;
                border: 1px solid $border-color;
                padding: $gap-1;
                border-radius: $small-border-radius;
            }
        }
    }

    .forms {
        display: grid;

        h2 {
            font-weight: 500;
            font-size: $font-size-4;
        }

        .email, .password {
            padding: $gap-5 0;
        }
    
        .email {
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
        }
    }

    @media (min-width: 300px) {

        .credentials-top {
    
            .basic-info {
                .name {
                    font-size: $font-size-7;
                }
            
                .date-joined {
                    font-size: $font-size-2;
                }
            }
    
            .account-status {
                font-size: $font-size-2;
            }
        }
    }

    @media (min-width: 400px) {
        .credentials-top {
            grid-template-columns: 1fr 75px;

            .account-status {
                text-align: right;
            }
        }
    }

    @media (min-width: 500px) {
        .credentials-top {
            .basic-info {
                .name {
                    font-size: $font-size-8;
                    margin-bottom: $gap-3;
                }

                .date-joined {
                    font-size: $font-size-3;
                }
            }

            .account-status {
                text-align: right;
            }
        }
    }

    @media (min-width: 550px) {

        .credentials-top {
            margin-bottom: $gap-6;

            .basic-info {
                .name {
                    font-size: $font-size-9;
                }

                .date-joined {
                    font-size: $font-size-4;
                }
            }
        }

        .forms {
            grid-template-columns: 1fr 1fr;

            .email, .password {
                padding: 0;
                padding: $gap-3 $gap-6;
            }

            .email {
                border: none;
                padding-left: 0;
                border-right: 1px solid $border-color;
            }

            .password {
                padding-right: 0;
            }
        }
    }

}