.nutrition-page {
    .heading, h2, .nutrient-grid, .meal-grid, .no-data {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .heading {
        position: relative;
        display: flex;
        align-items: flex-start;

        .datetime-input {
            width: 30px;
            overflow: visible;

            img {
                cursor: pointer;
            }

            input {
                padding-right: 0;
                background: none;
                border: none;
            }

            input:focus {
                outline: none;
            }
        }

        .datetime-input:focus {
            outline: none;
        }
    }

    .nutrient-grid {
        margin-bottom: $gap-6;
    }

    .meal-grid {
        display: grid;
        gap: $gap-4;
    }

    

    @media (min-width: 400px) {
        .nutrient-grid {
            margin-bottom: $gap-7;
        }
    }

    @media (min-width: 600px) {
        .meal-grid {
            padding-left: $gap-8;
            position: relative;

            .meal {
                position: relative;
                max-width: 500px;
            }

            .meal:before {
                content: "";
                position: absolute;
                height: 20px;
                width: 20px;
                top: calc(50% - 10px - 2px);
                left: (-$gap-8 / 2) - 4 - 10;
                border: 4px solid $input-black;
                border-radius: 50%;
                background-color: $background-gray;
            }
        }

        .meal-grid:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 4px;
            background-color: $input-black;
            left: ($gap-8 / 2) - 2;  
        }
    }

    @media (min-width: 900px) {
        h2 {
            font-size: $font-size-8;
        }

        .meal-grid {
            padding-left: $gap-9;

            .meal:before {
                height: 30px;
                width: 30px;
                top: calc(50% - 15px - 3px);
                left: (-$gap-9 / 2) - 6 - 15;
                border: 6px solid $input-black;
            }
        }

        .meal-grid:before {
            width: 6px;
            left: ($gap-9 / 2) - 3;  
        }
    }
}