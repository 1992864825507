.time-category-grid {
    display: grid;
    gap: $gap-4;
    max-width: 1300px;

    .time-category-summary {
        color: inherit;
        border-top: 10px solid white;
        display: block;

        .category-name {
            font-weight: 700;
            font-size: $font-size-5;
            margin-bottom: $gap-1;
        }

        .category-total {
            font-weight: 300;
        }
    }

    @media (min-width: 250px) {
        .time-category-summary {
            .category-name {
                font-size: $font-size-6;
                margin-bottom: $gap-2;
            }
        }
    }

    @media (min-width: 500px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 750px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1000px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1250px) {
        grid-template-columns: repeat(5, 1fr);
    }
}

.time-category-grid.empty {
    display: block;
    font-weight: 300;

    @media (min-width: 300px) {
        font-size: $font-size-4;
    }
}