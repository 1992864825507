.home-page {
    section {
        margin-bottom: $gap-6;

        .quantity-grid, .time-category-grid-home, .account-grid {
            display: grid;
            gap: $gap-3;
            max-width: 2900px;
        }
    }

    .insights {
        a {
            font-size: $font-size-4;
        }
    }

    @media (min-width: 700px) {
        section {
            margin-bottom: $gap-7;

            .quantity-grid, .time-category-grid-home, .account-grid {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media (min-width: 1100px) {
        section {
            margin-bottom: $gap-8;

            .quantity-grid, .time-category-grid-home, .account-grid {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }

    @media (min-width: 1600px) {
        section {
            .quantity-grid, .time-category-grid-home, .account-grid {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
    }

    @media (min-width: 2000px) {
        section {
            .quantity-grid, .time-category-grid-home, .account-grid {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            }
        }
    }

    @media (min-width: 2400px) {
        section {
            .quantity-grid, .time-category-grid-home, .account-grid {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
            }
        }
    }
}