.color-picker {
    .current-color {
        width: 40px;
        height: 25px;
        border-radius: 2px;
        cursor: pointer;
    }

    .twitter-picker {
        background: none !important;
        box-shadow: none !important;

        input {
            padding: 2px !important;
        }
    }
}