.nav-dropdown {
    background-color: $dirty-white;
    border-radius: $border-radius;
    box-shadow: $high-box-shadow;
    overflow: hidden;
    font-size: $font-size-3;

    .nav-dropdown-top {
        border-bottom: 1px solid $border-color;
        padding: $gap-3 $gap-4;
        
        .user-name {
            font-weight: 500;
        }

        .user-email {
            font-size: $font-size-2;
            color: $text-gray;
            font-weight: 300;
        }
    }

    .nav-dropdown-bottom {
        padding: $gap-3 $gap-4;
        line-height: 2;
        
        a {
            display: block;
            font-weight: 300;
        }
    
        .logout-button {
            color: $hyperlink-color;
            cursor: pointer;
            font-weight: 300;
        }
    }

    @media (min-width: 300px) {
        font-size: $font-size-4;

        .nav-dropdown-top {
            padding: $gap-4 $gap-5;
        }

        .nav-dropdown-bottom {
            padding: $gap-3 $gap-5;
            line-height: 2;
        }
    }

}