.measurement-form {
    overflow: hidden;

    h1 {
        border-bottom: 2px solid $quantities-color;
    }

    input[type=submit] {
        max-width: 100px;
        margin-left: auto;
    }

    @media (min-width: 400px) {
        .top-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 75px 1fr;
            align-items: end;
        }
    }
}