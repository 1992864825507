.account-summary {
    color: inherit;

    .account-name {
        font-weight: 700;
        margin-bottom: $gap-1;
    }

    .account-value {
        font-size: $font-size-4;
        font-weight: 300;
        border-bottom: 1px solid $border-color;
        padding-bottom: $gap-1;
        margin-bottom: $gap-2;

        .units {
            font-size: $font-size-2;
        }
    }

    .account-value.hidden {
        .units {
            opacity: 0;
        }
    }

    .account-value.no-series {
        border-bottom: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    svg.svg-series {
    
        path {
            stroke: transparentize($quantities-color, 0.6);
        }
    }

    @media (min-width: 250px) {
        .account-name {
            font-size: $font-size-4;
        }

        .account-value {
            font-size: $font-size-5;
            padding-bottom: $gap-2;
    
            .units {
                font-size: $font-size-3;
            }
        }
    }
}