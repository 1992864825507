body {
    font-family: $font, Arial, sans-serif;
    font-size: $font-size-3;
    color: $text-black;
}

em {
    font-style: italic;
    text-decoration: underline;
}

h1 {
    font-weight: 600;
    font-size: $font-size-4;
    margin-bottom: $gap-4;

    @media (min-width: 250px) {
        font-size: $font-size-5;
    }

    @media (min-width: 300px) {
        font-size: $font-size-6;
    }

    @media (min-width: 400px) {
        font-size: $font-size-7;
            margin-bottom: $gap-5;
    }

    @media (min-width: 500px) {
        font-size: $font-size-8;
    }

    @media (min-width: 600px) {
        font-size: $font-size-9;
    }
}

h2 {
    font-weight: 700;
    font-size: $font-size-5;
    margin-bottom: $gap-2;

    @media (min-width: 300px) {
        font-size: $font-size-6;
        margin-bottom: $gap-3;
    }

    @media (min-width: 500px) {
        font-size: $font-size-7;
        margin-bottom: $gap-4;
    }
}

.empty {
    font-weight: 300;
}

input {
    display: block;
    color: $input-black;
    background-color: white;
    width: 100%;
    border: 1px solid $border-color;
    border-radius: $small-border-radius;
    padding: $gap-2 $gap-2;
    font-size: $font-size-2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=submit] {
    background-color: $primary;
    color: white;
    cursor: pointer;
    font-weight: 800;
    border: none;
}

input:disabled {
    opacity: 0.5;
}

input.error-input {
    color: $error-red;
    background-color: transparentize($error-red, 0.7);
    border: 1px solid darken($error-red, 1);
}

.toggle-input {
    display: flex;
    align-items: center;

    label {
        margin-left: $gap-2;
    }

    .react-toggle--checked.react-toggle--focus, .react-toggle--checked:hover {
        .react-toggle-track {
            background-color: $primary;
        }
    }

    .react-toggle--checked {
        .react-toggle-track {
            background-color: lighten($primary, 2);
        }
    }
}

.react-select__control {
    min-height: 0 !important;
    overflow: hidden;

    .react-select__indicator {
        padding: $gap-1 !important;
    }

    .react-select__single-value {
        min-height: 16px;
    }
}

.inputs {
    display: grid;
    gap: $gap-5;
}

a {
    color: $hyperlink-color;
    text-decoration: none;
}

.error {
    color: $error-red;
    font-size: $font-size-1;
}

button {
    cursor: pointer;
    background-color: $black-2;
    color: white;
    padding: $gap-2 $gap-2;
    border-radius: $small-border-radius;
    border: none;
    font-weight: 700;
    width: fit-content;
}

.logo {
    font-family: $logo-font;
    background-color: $black-1;
    color: white;
    width: fit-content;
    padding: $gap-1 $gap-2;
    letter-spacing: 1px;
}

.notice {
    text-align: center;
    padding: $gap-2 0;
    line-height: 1.5;
    font-style: italic;
    font-weight: 300;

    @media (min-width: 250px) {
        padding: $gap-3 $gap-1;
    }

    @media (min-width: 300px) {
        padding: $gap-4 $gap-2;
    }

    @media (min-width: 400px) {
        padding: $gap-5 $gap-3;
        line-height: 1.6;
        font-size: $font-size-4;
    }

    @media (min-width: 500px) {
        padding: $gap-6 $gap-4;
        line-height: 1.7;
    }
}

.delete-modal {

    form {
        position: relative;
        text-align: center;

        .icon {
            width: 40px;
            display: block;
            opacity: 0.5;
            margin: $gap-4 auto;
        }

        .proposal {
            font-size: $font-size-5;
            margin-bottom: $gap-3;
            font-weight: 600;
        }

        .warning {
            color: $text-gray;
            font-size: $font-size-2;
            line-height: 1.7;
            margin-bottom: $gap-5;
        }

        input {
            background-color: $danger-red;
        }
    }

    @media (min-width: 300px) {
        form {
            .icon {
                width: 50px;
                margin: $gap-5 auto;
            }

            .proposal {
                font-size: $font-size-6;
                margin-bottom: $gap-4;
            }

            .warning {
                font-size: $font-size-3;
                margin-bottom: $gap-6;
            }
        }
    }
}

.help-modal.modal {
    .modal-box {
        max-width: 325px;
    }

    .modal-heading {
        margin: $gap-3 0;
        font-size: $font-size-5;
        font-weight: 500;
    }

    p {
        font-weight: 300;
        margin-bottom: $gap-2;
        font-size: $font-size-2;
    }

    @media (min-width: 250px) {
        .modal-heading {
            margin-bottom: $gap-4;
            font-size: $font-size-6;
        }

        p {
            margin-bottom: $gap-3;
            font-size: $font-size-3;
            line-height: 1.4;
            color: $text-gray;
        }
    }

    @media (min-width: 300px) {
        .modal-heading {
            font-size: $font-size-7;
        }
    }
}

.no-data {
    font-weight: 300;
}

.model-form.white-box {
    padding: 0;

    h1, form {
        padding: $gap-3;
    }

    h1 {
        font-size: $font-size-3;
        font-weight: 600;
    }

    label {
        color: $text-gray;
        font-weight: 700;
        font-size: $font-size-2;
        margin-bottom: $gap-1;
        display: block;
    }

    .input {
        margin-bottom: $gap-4;   
    }

    @media (min-width: 250px) {
        h1, form {
            padding: $gap-4;
        }

        h1 {
            font-size: $font-size-4;
        }
    }

    @media (min-width: 300px) {

        h1, form {
            padding: $gap-5;
        }

        h1 {
            font-size: $font-size-5;
        }
    }

    @media (min-width: 400px) {
        h1, form {
            padding: $gap-5 $gap-6;
        }

        h1 {
            font-size: $font-size-6;
        }
    }

    @media (min-width: 500px) {

        h1 {
            font-size: $font-size-7;
        }
    }
}