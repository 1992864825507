.food {
    position: relative;

    .food-description {
        margin-bottom: $gap-3;

        .food-name {
            font-weight: 700;
            font-size: $font-size-4;
            margin-bottom: $gap-1;
        }
    
        .food-notes {
            font-size: $font-size-2;
            color: $text-gray;
            line-height: 1.6;
        }
    }

    .nutrients {
        .per {
            font-weight: 200;
            font-size: $font-size-4;
            color: $text-gray;
            margin-bottom: $gap-2;
        }

        .nutrients-grid {
            .nutrient {
                padding: $gap-2 0;
                border-bottom: 1px solid $border-color;
                font-weight: 500;
    
                .value {
                    font-weight: 300;
                }
            }
    
            .nutrient:nth-child(1) {
                border-top: 1px solid $border-color;
            }
        }

    }

    @media (min-width: 350px) {
        padding: $gap-4;

        .food-description {
            margin-bottom: $gap-4;
    
            .food-name {
                font-size: $font-size-5;
                margin-bottom: $gap-2;
            }
        
            .food-notes {
                font-size: $font-size-3;
            }
        }

        .nutrients {
            .per {
                font-size: $font-size-5;
                margin-bottom: $gap-3;
            }

            .nutrients-grid {
                .nutrient {
                    font-size: $font-size-4;
                }
            }
    
        }
    }

    @media (min-width: 500px) {
        padding: $gap-5;

        .food-description {
            margin-bottom: $gap-4;
    
            .food-name {
                font-size: $font-size-8;
            }
        
            .food-notes {
                font-size: $font-size-5;
            }
        }

        .nutrients {
            .per {
                font-size: $font-size-5;
                margin-bottom: $gap-4;
            }

            .nutrients-grid {
                display: grid;
                grid-template-columns: 1fr 1fr;

                .nutrient {
                    font-size: $font-size-4;
                }

                .nutrient:nth-child(2) {
                    border-top: 1px solid $border-color;
                }

                .nutrient:nth-child(7) {
                    grid-column: span 2;
                }
            } 
        }
    }

    @media (min-width: 800px) {
        padding: $gap-6;
        
        .food-description {
            margin-bottom: $gap-5;
    
            .food-name {
                font-size: $font-size-9;
            }
        
            .food-notes {
                font-size: $font-size-6;
            }
        }
    }

    @media (min-width: 1000px) {
        .nutrients {
            .nutrients-grid {
                grid-template-columns: repeat(4, 1fr);

                .nutrient:nth-child(3), .nutrient:nth-child(4) {
                    border-top: 1px solid $border-color;
                }
            }
        }
    }
}