.nutrient-grid {
    display: grid;
    gap: $gap-3;
    max-width: 1300px;

    .nutrient {
        color: inherit;
        border-top: 10px solid black;
        
        .nutrient-name {
            font-weight: 700;
            font-size: $font-size-5;
            margin-bottom: $gap-1;
        }

        .nutrient-value {
            font-size: $font-size-7;
            font-weight: 200;

            .units {
                font-size: $font-size-3;
            }
        }
    }

    .calories {
        border-top-color: $calorie-color;
    }

    .carbohydrates {
        border-top-color: $carbohydrates-color;
    }

    .sugar {
        border-top-color: $sugar-color;
    }

    .protein {
        border-top-color: $protein-color;
    }

    .fat {
        border-top-color: $fat-color;
    }

    .fibre {
        border-top-color: $fibre-color;
    }

    .salt {
        border-top-color: $salt-color;
    }

    @media (min-width: 250px) {
        .nutrient {
            .nutrient-name {
                font-size: $font-size-6;
                margin-bottom: $gap-2;
            }
    
            .nutrient-value {
                font-size: $font-size-9;
    
                .units {
                    font-size: $font-size-4;
                }
            }
        }
    }

    @media (min-width: 350px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 500px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 600px) {
        grid-template-columns: repeat(4, 1fr);
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(7, 1fr);
    }
}

