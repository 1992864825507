.base {
    display: grid;
    grid-template-columns: $compact-sidebar-width 1fr;
    grid-template-rows: $nav-height 1fr;
    background-color: $background-gray;
    
    .sidebar {
        grid-area: 1 / 1 / 3 / 2;
    }

    nav {
        grid-area: 1 / 2 / 2 / 3;
    }

    main {
        grid-area: 2 / 2 / 3 / 3;
        padding: $gap-3;
        padding-bottom: 0;
        overflow-y: scroll;
    }

    main::after {
        display: block;
        height: $gap-3;
        content: "";
    }

    @media (min-width: 800px) {
        grid-template-columns: $extended-sidebar-width 1fr;
    }
}