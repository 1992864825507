.food-chart {
    .highcharts-column-series {
        rect {
            fill: transparentize($food-color, 0.4);
            stroke: transparentize($food-color, 0.3);;
        }
    }

    .highcharts-column-series.highcharts-series-hover {
        rect {
            fill: transparentize($food-color, 0.3);
            stroke: transparentize($food-color, 0.2);;
        }
    }

    .chart-controls {
        padding: $gap-3 $gap-1;

        label {
            font-size: $font-size-1;
        }
    }



    @media (min-width: 300px) {
        .chart-controls {
            padding: $gap-4 $gap-2;
    
            label {
                font-size: $font-size-2;
            }
        }
    }
    
    @media (min-width: 400px) {
        .chart-controls {
            padding: $gap-5 $gap-3;
        }
    }

}