.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: transparentize($background-gray, 0.3);
    align-items: flex-end;
    justify-content: center;
    padding: $gap-3;
    z-index: 10000;

    .modal-box {
        position: relative;
        text-align: center;
        padding: $gap-4;
        max-width: 300px;

        .close {
            position: absolute;
            width: 10px;
            opacity: 0.5;
            right: $gap-3;
            cursor: pointer;
        }
    }

    @media (min-width: 300px) {
        .modal-box {
            padding: $gap-5;

            .close {
                right: $gap-4;
                top: $gap-4;
            }
        }
    }

    @media (min-width: 400px) {
        align-items: center;
    }
}

.modal.displayed {
    display: flex;
}