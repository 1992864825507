.meal-form {

    h1 {
        border-bottom: 2px solid $food-color;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    form {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .react-select-food {

        * {
            cursor: text;
        }

        .react-select__indicators {
            display: none;
        }
    }
    .react-select__single-value {
        color: $input-black;
    }

    .toggle {
        color: $hyperlink-color;
        font-size: $font-size-1;
        cursor: pointer;
        margin-bottom: $gap-2;
    }

    .nutrient-inputs {
        display: grid;
        gap: $gap-4;
        margin-bottom: $gap-6;

        .input {
            margin-bottom: 0;
        }
    }

    .component {
        position: relative;
        margin-top: $gap-5;
        margin-bottom: $gap-5;

        .remove {
            position: absolute;
            left: -$gap-4;
            top: -$gap-3;
            width: 20px;
            opacity: 0.5;
            border: 1px solid $border-color;
            padding: $gap-1;
            border-radius: 50%;
            cursor: pointer;
        }

        .amount {
            display: grid;
            grid-template-columns: 80px 85px;
            align-items: flex-end;
    
            .input {
                margin-bottom: 0;
    
                input {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    border-right: none;
                    text-align: right;
                    height: 33px;
                }
            }
    
            .units {
    
                .react-select__control {
                    border: 1px solid $border-color;
                    display: flex;
                    font-size: $font-size-2;
                    padding: 1.5px 0;
                    line-height: 1;
                    height: 33px;
                    background-color: white;
                    font-weight: 300;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
    
                    .react-select__value-container {
                        padding-left: $gap-1;
                    }
                }
            }
    
            span.units {
                border: 1px solid $border-color;
                background-color: white;
                padding: #{$gap-2 + 1.5px} $gap-2;
                padding-left: 0;
                line-height: 1;
                font-size: $font-size-2;
                width: fit-content;
                font-weight: 300;
                display: block;
                border-left: none;
                border-top-right-radius: $small-border-radius;
                border-bottom-right-radius: $small-border-radius;
            }
        }
    }

    .bottom-row {
        display: flex;
        justify-content: space-between;

        .add {
            display: block;
            width: 25px;
            height: 25px;
            opacity: 0.4;
            margin-right: $gap-2;
            cursor: pointer;
        }

        input[type=submit] {
            padding-left: $gap-5;
            padding-right: $gap-5;
            width: fit-content;
        }
    }

    @media (min-width: 300px) {
        .nutrient-inputs {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: 400px) {
        .toggle {
            font-size: $font-size-2;
        }

        .nutrient-inputs {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    @media (min-width: 450px) {

        .component {
            display: grid;
            align-items: flex-start;
            gap: $gap-4;
            grid-template-columns: 2fr 165px;
        }
    }

    @media (min-width: 600px) {
        .top-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 280px 1fr;
            align-items: end;
        }
    }

    @media (min-width: 700px) {
        .nutrient-inputs {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}