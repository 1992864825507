.datetime-input {
    position: relative;

    input {
        padding-left: 33px;
        cursor: pointer;
    }

    img.icon {
        position: absolute;
        top: 0;
        height: 33px;
        padding: $gap-2;
        background: none;
        opacity: 0.25;
    }

    .date-modal {
        .modal-box {
            position: relative;
            padding: $gap-4;

            button.now {
                position: absolute;
                top: $gap-3;
                left: $gap-3;
                background: none;
                color: $hyperlink-color;
                font-weight: 400;
                padding: 0;
            }

            button.now:focus, button.now:active {
                outline: none;
            }

            .date-heading {
                font-weight: 800;
                margin: $gap-3 0;
                text-align: center;
            }

            .month-row {
                display: flex;
                justify-content: center;
                margin: $gap-2 0;
                font-size: $font-size-2;

                .left {
                    margin-right: $gap-3;
                }

                .month {
                    margin-right: $gap-1;
                }

                .year {
                    margin-left: $gap-1;
                }

                .right {
                    margin-left: $gap-3;
                }

                img {
                    width: 10px;
                    height: 16px;
                    cursor: pointer;
                }
            }

            .table-container {
                height: 140px;
                
                table {
                    border-collapse: collapse;
                    margin: 0 auto;
                    
                    thead {
                        tr {
                            th {
                                font-weight: 700;
                            }
                        }
                    }
                    thead, tbody {
                        tr {
                            td, th {
                                line-height: 1;
                                height: 20px;
                                width: 20px;
                                font-size: $font-size-1;
                                text-align: center;
                                vertical-align: middle;
                            }
    
                            td {
                                font-weight: 300;
                                cursor: pointer;
                            }
    
                            td.selected {
                                background: $primary;
                                color: white;
                                border-radius: 50%;
                                font-weight: 600;
                            }
    
                            td.invalid {
                                cursor: default;
                                opacity: 0.3;
                            }
                        }
                    }
                }
            }

            .time-row {
                display: flex;
                margin: $gap-3 auto;
                align-items: center;
                padding-top: $gap-5;
                border-top: 1px solid $border-color;

                .date-heading {
                    margin: 0;
                    margin-right: $gap-3;
                }

                input {
                    background-color: #ffffff00;
                    padding: 0;
                    width: 40px;
                    padding: $gap-1 $gap-1;
                    text-align: center;
                    font-weight: 300;
                }

                input:first-of-type {
                    border-right: none;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                input:nth-of-type(2) {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

            }
            select.timezone {
                font-size: $font-size-1;
                cursor: pointer;
                text-align: center;
                width: 100%;
                font-weight: 300;
            }
        
        }
    }

    @media (min-width: 250px) {

        .date-modal {
            .modal-box {
                .date-heading {
                    margin-bottom: $gap-4;
                }
    
                .month-row {
                    margin: $gap-3 0;
                    font-size: $font-size-3;
    
                    .left {
                        margin-right: $gap-4;
                    }
    
                    .right {
                        margin-left: $gap-4;
                    }
    
                    img {
                        width: 12px;
                        height: 18px;
                    }
                }
                
                .table-container {
                    height: 7 * 24px;
                    
                    table {
                        thead, tbody {
                            tr {
                                td, th {
                                    height: 24px;
                                    width: 24px;
                                    font-size: $font-size-2;
                                }
                            }
                        }
                    }
                }
            
                .time-row {
                    select.timezone {
                        font-size: $font-size-1;
                    }
                }
            }
        }
    }

    @media (min-width: 300px) {

        .date-modal {
            .modal-box {
    
                .month-row {
                    margin: $gap-4 0;
                    font-size: $font-size-4;
    
                    img {
                        width: 14px;
                        height: 22px;
                    }
                }
                
                .table-container {
                    height: 7 * 30px;
                    
                    table {
                        thead, tbody {
                            tr {
                                td, th {
                                    height: 30px;
                                    width: 30px;
                                    font-size: $font-size-3;
                                }
                            }
                        }
                    }
                }
            
                .time-row {
    
                    input {
                        font-size: $font-size-4;
                        width: 50px
                    }
    
                }
                select.timezone {
                    font-size: $font-size-2;
                }
            }
        }
    }
}