.user-deletion-form {
    font-size: $font-size-2;

    h2 {
        margin-bottom: $gap-5;
    }

    button.delete {
        background-color: $danger-red;
        margin: 0 auto;
        display: block;
    }
    
    .danger-zone {
        height: 0;
        overflow: hidden;
        transition-property: height;
        transition-duration: 1s;
        transition-timing-function: ease-in-out;
        transition: 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
            font-size: $font-size-1;
            line-height: 1.5;
            margin: $gap-5 0;
            text-align: center;
        }

        .options {
            display: grid;
            gap: $gap-7;
            width: 100%;
            max-width: 200px;

            .stay {
                button {
                    background-color: $primary;
                    margin: 0 auto;
                    display: block;
                }
            }

            .go {
                input[type=password] {
                    margin-bottom: $gap-3;
                }

                input[type=submit] {
                    background-color: $danger-red;
                    margin: 0 auto;
                    display: block;
                    width: fit-content
                }
            }


        }
    }

    @media (min-width: 300px) {
        font-size: $font-size-3;

        .danger-zone {
            p {
                font-size: $font-size-2;
            }
        }
    }

    @media (min-width: 400px) {
        .danger-zone {

            .options {
                grid-template-columns: 90px 1fr;
                max-width: 400px;
                gap: $gap-4;

                .go {
                    display: flex;

                    input[type=password] {
                        margin-right: $gap-2;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.user-deletion-form.cocked {

    button.delete {
        opacity: 0.5;
    }
    
    .danger-zone {
        height: 300px;
    }

    @media (min-width: 400px) {
        .danger-zone {
            height: 180px;

            .go {
                position: relative;

                .error {
                    position: absolute;
                    top: -15px;
                    left: $small-border-radius;
                }
            }

            
        }
    }
}