.time-category-form {
    overflow: hidden;
    max-width: 600px;
    margin: 0 auto;

    h1 {
        border-bottom: 2px solid $time-color;
    }

    .bottom-row {
        align-items: flex-end;
        justify-content: space-between;

        .input {
            margin-right: $gap-4;
        }
    }


    input[type=submit] {
        padding-left: $gap-5;
        padding-right: $gap-5;
        margin-top: $gap-6;
    }

    @media (min-width: 400px) {
        input[type=submit] {
            width: fit-content;
        }

        .bottom-row {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
    
            .input {
                margin-right: $gap-4;
            }
        }
    }

    @media (min-width: 500px) {
        .top-row {
            display: grid;
            gap: $gap-4;
            grid-template-columns: 1fr 1fr;
            align-items: end;
        }
    }
}