.compare-page {

    .white-box, h1 {
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    }

    .notice {
        padding: $gap-7 $gap-4;
    }
    

    .quantities {
        padding: $gap-4;
        margin-bottom: $gap-4;

        .clear {
            font-size: $font-size-2;
            margin-left: auto;
            margin-bottom: $gap-1;
            margin-top: -$gap-1;
            display: block;
            width: fit-content;
        }

        .selectors {
            display: grid;
            gap: $gap-4;
            margin-bottom: $gap-4;
    
            .selector {
                label {
                    margin-bottom: $gap-1;
                    display: block;
                    font-weight: 700;
                    font-size: $font-size-2;
                    color: $text-gray;
                }
            }
        }

        input[type=submit] {
            width: fit-content;
        }
    }
    

    @media (min-width: 500px) {
        .selectors {
            grid-template-columns: 1fr 1fr;
        }
    }
}