.settings-page {

    .white-box {
        max-width: 550px;
        margin: 0 auto;
        margin-bottom: $gap-5;
        padding: $gap-3 $gap-4;

        h2 {
            font-size: $font-size-6;
            font-weight: 700;
            margin-bottom: $gap-4;
        }

        label {
            font-weight: 600;
            margin-bottom: $gap-1;
            display: block;
            font-size: $font-size-2;
        }

        .grid-2 {
            display: grid;
            gap: $gap-4;
        }
    }

    .white-box:last-child {
        margin-bottom: 0;
    }

    @media (min-width: 300px) {
        .white-box {
            padding: $gap-4 $gap-5;
        }
    }

    @media (min-width: 400px) {
        .white-box {

            .grid-2 {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    @media (min-width: 550px) {
        .white-box {
            padding: $gap-5 $gap-6;
        }
    }
}