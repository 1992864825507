.quick-add-session-modal {
    .modal-box {
        max-width: 350px;
        width: 100%;

        .times {
            display: grid;
            grid-template-columns: 70px 1fr 70px;
            gap: 0;
            margin: 0 auto;
            margin-bottom: $gap-4;
            align-items: center;
            max-width: 160px;
    
            .time {
                position: relative;

                button {
                    background: none;
                    color: $hyperlink-color;
                    padding: 0;
                    position: absolute;
                    left: -15px;
                    top: calc(50% - 5px);
                    width: 10px;
                    height: 10px;
                    background-color: $time-color;
                    border-radius: 50%;
                }
                .inputs {
                    gap: 0;
                    display: grid;
                    grid-template-columns: 35px 35px;
    
                    input {
                        margin: 0;
                        padding: $gap-2;
                        font-size: $font-size-3;
                    }
    
                    input:nth-child(1) {
                        border-right: none;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        text-align: right;
                        padding-right: $gap-1;
    
                    }
                    input:nth-child(2) {
                        border-left-style: dashed;
                        padding-left: $gap-1;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }
            }

            .time:nth-child(3) {
                button {
                    right: -15px;
                    left: auto;
                    border-radius: 50%;
                }
            }

            .to {
                font-size: $font-size-1;
            }
        }

        .react-select {
            margin: $gap-4 0;
        }
    }

    @media (min-width: 300px) {
        .modal-box {
    
            .times {
                
                .time {
    
                    button {
                        left: -27px;
                        top: calc(50% - 9px);
                        width: 18px;
                        height: 18px;
                    }
                }
    
                .time:nth-child(3) {
                    button {
                        right: -27px;
                        width: 18px;
                        height: 18px;
                    }
                }
    
                .to {
                    font-size: $font-size-1;
                }
            }
    
            .react-select {
                margin: $gap-4 0;
            }
        }
    }
    

    
}