.not-found-page {
    background-color: $black-2;
    display: flex;
    flex-direction: column;
    padding: $gap-3;
    align-items: center;
    justify-content: center;
    color: white;

    h1 {
        font-size: $font-size-8;
        margin-bottom: $gap-4;
    }
}