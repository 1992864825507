$width: 320px;

@keyframes flickerAnimation { /* flame pulses */
    0%   { opacity:1; }
    50%  { opacity:0.5; }
    100% { opacity:1; }
}

.accounts-page {
    .accounts-grid {
        display: grid;
        gap: $gap-3;
        max-width: (10 * $width + 9 * $gap-3);
        margin: 0 auto;
    }

    .no-accounts {
        text-align: center;
        margin-top: $gap-8;
        font-weight: 300;

        .pointer {
            display: flex;
            align-items: center;
            position: absolute;
            top: 368px;

            img {
                width: 40px;
                margin-right: $gap-4;
                opacity:1;  
                animation: flickerAnimation 1s infinite;
            }
        }

        @media (min-width: 800px) {
            .pointer {
                top: 378px;
            }
        }
    }

    @media (min-width: $compact-sidebar-width + (1 * $width) + (2 * $gap-3)) {
        .accounts-grid {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media (min-width: $extended-sidebar-width + (2 * $width) + (3 * $gap-3)) {
        .accounts-grid {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    @media (min-width: $extended-sidebar-width + (3 * $width) + (4 * $gap-3)) {
        .accounts-grid {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }

    @media (min-width: $extended-sidebar-width + (4 * $width) + (5 * $gap-3)) {
        .accounts-grid {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    @media (min-width: $extended-sidebar-width + (5 * $width) + (6 * $gap-3)) {
        .accounts-grid {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    @media (min-width: $extended-sidebar-width + (6 * $width) + (7 * $gap-3)) {
        .accounts-grid {
            grid-template-columns: repeat(7, 1fr);
        }
    }

    @media (min-width: $extended-sidebar-width + (7 * $width) + (8 * $gap-3)) {
        .accounts-grid {
            grid-template-columns: repeat(8, 1fr);
        }
    }

    @media (min-width: $extended-sidebar-width + (8 * $width) + (9 * $gap-3)) {
        .accounts-grid {
            grid-template-columns: repeat(9, 1fr);
        }
    }

    @media (min-width: $extended-sidebar-width + (9 * $width) + (10 * $gap-3)) {
        .accounts-grid {
            grid-template-columns: repeat(10, 1fr);
        }
    }

    
}