.sidebar {
    background-color: $black-1;

    .logo {
        padding: 0;

        img {
            height: $nav-height * 0.6;
            margin: $nav-height * 0.2 auto;
            display: block;
            background: none;
        }

        span {
            display: none;
        }
    }

    .sections {
        display: grid;
        gap: $gap-6;
        width: fit-content;
        text-align: left;
        margin: 0 auto;
        margin-top: $gap-8;

        a {
            display: flex;
            align-items: center;
            position: relative;
            
            img {
                width: 20px;
                filter: invert(1);
                background-color: #ffffff00;
            }

            .specifier {
                position: absolute;
                width: 12px;
                top: -10px;
                right: -10px;
            }

            span {
                display: none;
            }

            text-align: left;
        }

        a.tool {
            margin-top: $gap-8;
        }

        a.secondary-tool {
            margin-top: 0;
        }
    }

    @media (min-width: 800px) {
        .logo {
            img {
                display: none;
            }

            span {
                display: block;
                text-align: center;
                line-height: $nav-height;
                font-size: $font-size-7;
                background: none;
            }
        }

        .sections {
            margin-left: $gap-4;

            a {
                span {
                    display: inline;
                    margin-left: $gap-2;
                    color: white;
                }

                .specifier {
                    display: none;
                }
            }

            .tool {
                font-size: $font-size-2;
            }
        }
        
    }
}

.quantities-base {
    .sidebar {
        background-color: $quantities-color;
    }
}

.food-base {
    .sidebar {
        background-color: $food-color;
    }
}

.time-base {
    .sidebar {
        background-color: $time-color;
    }
}

.money-base {
    .sidebar {
        background-color: $money-color;
    }
}