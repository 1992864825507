.transactions-table {
    padding: 0;
    overflow: hidden;

    .table-container {
        overflow-x: scroll;
        display: flex;
        align-items: flex-end;

        .table {
            display: table;
            border-collapse: collapse;
            width: 100%;
        }
    }

    .table-container.showing-dropdown {
        min-height: 130px;
    }

    .table-container.loading {
        margin-bottom: 500px;
    }
}