.session-row {
    font-size: $font-size-1;
    font-weight: 300;
    display: table-row;
    overflow: hidden;

    .cell {
        display: table-cell;
        vertical-align: middle;
        padding: $gap-3 $gap-2;
        white-space: nowrap;
        position: relative;

        .circle {
            width: 15px;
            height: 15px;
            border-radius: 50%;
        }
    }

    .cell:first-child {
        padding-left: $gap-3;
    }

    .cell:last-child {
        padding-right: $gap-3;
    }

    .cell.value {
        font-weight: 600;
        font-size: $font-size-2;
    }

    .cell.notes {
        font-style: italic;
        width: 100%;
    }

    @media (min-width: 250px) {
        font-size: $font-size-2;

        .cell {
            padding: $gap-4 $gap-2;
        }

        .cell:first-child {
            padding-left: $gap-4;
        }

        .cell:last-child {
            padding-right: $gap-4;
        }

        td.value {
            font-size: $font-size-3;
        }
    }

    @media (min-width: 500px) {
        font-size: $font-size-3;

        .cell {
            padding: $gap-4 $gap-3;

            .circle {
                width: 20px;
                height: 20px;
            }
        }

        .cell:first-child {
            padding-left: $gap-5;
        }

        .cell:last-child {
            padding-right: $gap-5;
        }

        .cell.value {
            font-size: $font-size-4;
        }
    }

    @media (min-width: 800px) {
        font-size: $font-size-4;

        .cell.value {
            font-size: $font-size-5;
        }
    }
}

.session-row.selectable {
    cursor: pointer;
}

.session-row:last-child {
    .cell {
        .dropdown-container {
            .dropdown {
                bottom: 0;
                top: auto;
            }
        }
    }
}

.session-row:nth-child(odd) {
    background-color: darken($dirty-white, 3);
}

.session-row.selected {
    background-color: darken($dirty-white, 10);
    font-weight: 500;
}