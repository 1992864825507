.transaction-row {
    font-size: $font-size-1;
    font-weight: 300;
    display: table-row;

    .cell {
        display: table-cell;
        vertical-align: middle;
        padding: $gap-3 $gap-2;
        white-space: nowrap;
        position: relative;
        min-width: 30px;
    }

    .balance {
        text-align: right;
    }

    .cell:first-child {
        padding-left: $gap-3;
    }

    .cell:last-child {
        padding-right: $gap-3;
    }

    .cell.amount, .cell.balance {
        font-weight: 600;
        font-size: $font-size-2;
    }

    .cell.positive {
        color: $positive;
    }

    .cell.negative {
        color: $negative;
    }

    .cell.description {
        font-style: italic;
        width: 100%;
    }

    @media (min-width: 250px) {
        font-size: $font-size-2;

        .cell {
            padding: $gap-4 $gap-2;
            min-width: 40px;
        }

        .cell:first-child {
            padding-left: $gap-4;
        }

        .cell:last-child {
            padding-right: $gap-4;
        }

        .cell.amount, .cell.balance {
            font-size: $font-size-3;
        }
    }

    @media (min-width: 500px) {
        font-size: $font-size-3;

        .cell {
            padding: $gap-4 $gap-3;
            min-width: 50px;
        }

        .cell:first-child {
            padding-left: $gap-5;
        }

        .cell:last-child {
            padding-right: $gap-5;
        }

        .cell.amount, .cell.balance {
            font-size: $font-size-4;
        }
    }

    @media (min-width: 800px) {
        font-size: $font-size-4;

        .cell.amount, .cell.balance {
            font-size: $font-size-5;
        }
    }
}

.transaction-row:last-child {
    .cell {
        .dropdown-container {
            .dropdown {
                bottom: 0;
                top: auto;
            }
        }
    }
}

.transaction-row:nth-child(odd) {
    background-color: darken($dirty-white, 3);
}